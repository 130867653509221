<template>
    <div>
        <div id="mapContainer" class="o-basemap" />
    </div>
</template>

<script>
import mapboxgl from "mapbox-gl";

export default {
	name: "ClusterMap",
	components: {
	},
	props: {
        value: {},
		initialZoom: {
			type: Number,
			default: 1.9,
		},
	},
	data: () => ({
		zoomCoord: null,
		accessToken: process.env.VUE_APP_MAPGL_TOKEN,
		map: null
	}),
	mounted() {
		mapboxgl.accessToken = this.accessToken;
		this.map = new mapboxgl.Map({
			container: "mapContainer",
			style: "mapbox://styles/mapbox/streets-v11",
			center: [-40, 25],
			zoom: this.initialZoom,
		});

		// https://github.com/mapbox/mapbox-gl-js/issues/2268#issuecomment-401979967
		this.map.on("load", () => {
			const waiting = () => {
				if (!this.map.isStyleLoaded() || ! this.value.features) {
					setTimeout(waiting, 200);
				} else {
					this.map.addSource("clusterSource", {
						type: "geojson",
						data: this.value,
						cluster: true,
						clusterMaxZoom: 14,
						clusterRadius: 50,
					});

					this.map.addLayer({
						id: "clusters",
						type: "circle",
						source: "clusterSource",
						filter: ['has', 'point_count'],
                        paint: {
                            // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
                            // with three steps to implement three types of circles:
                            //   * Blue, 20px circles when point count is less than 100
                            //   * Yellow, 30px circles when point count is between 100 and 750
                            //   * Pink, 40px circles when point count is greater than or equal to 750
                            'circle-color': [
                                'step',
                                ['get', 'point_count'],
                                '#7c878e',
                                20,
                                '#ff9e1b',
                                100,
                                '#f28cb1',
                            ],
                            'circle-radius': [
                                'step',
                                ['get', 'point_count'],
                                20,
                                100,
                                30,
                                750,
                                40
                            ]
                        }
                    });
					this.map.addLayer({
						id: "cluster-count",
						type: "symbol",
						source: "clusterSource",
						filter: ["has", "point_count"],
						layout: {
							"text-field": '{point_count_abbreviated}',
							"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
							"text-size": 12,
						},
					});

					this.map.addLayer({
						id: "unclustered-point",
						type: "circle",
						source: "clusterSource",
						filter: ["!", ["has", "point_count"]],
						paint: {
                            'circle-color': '#11b4da',
                            'circle-radius': 4,
                            'circle-stroke-width': 1,
                            'circle-stroke-color': '#fff'
						},
					});

					// inspect a cluster on click
					this.map.on("click", "clusters", this.zoomHandler);
					this.map.on("mouseenter", "clusters", this.mouseEnter);
					this.map.on("mouseleave", "clusters", this.mouseLeave);
					this.map.on("mouseenter", "unclustered-point", this.mouseEnter);
					this.map.on("mouseleave", "unclustered-point", this.mouseLeave);
				}
			};
			waiting();
		});
	},
	created() {
	},
	destroyed() {
		this.map.remove();
		this.map = null;
	},
	methods: {
		mouseEnter() {
			this.map.getCanvas().style.cursor = "pointer";
		},
		mouseLeave() {
			this.map.getCanvas().style.cursor = "";
		},
		zoomHandler(e) {
			var features = this.map.queryRenderedFeatures(e.point, {
				layers: ["clusters"],
			});
			var clusterId = features[0].properties.cluster_id;
			this.zoomCoord = features[0].geometry.coordinates;
			this.map.getSource("clusterSource").getClusterExpansionZoom(clusterId, this.zoom);
		},
		zoom(err, zoom) {
			if (err) return;
			this.map.easeTo({
				center: this.zoomCoord,
				zoom: zoom,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.o-basemap {
	width: 100%;
	height: 100%;
}
</style>
