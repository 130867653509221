<template>
  <div class="card">
    <h1 class="alertTitle">{{ $t('Alerts & Notifications') }}</h1>
    <div class="p-grid">
      <div class="p-col-6">
        <Card style="width: 25rem; margin-bottom: 2em; margin-left: 4rem">
          <template #title>
            {{ $t('Suspect Scan Tickets') }}
          </template>
          <template #content>
            <div v-if="!loading">
              <p :class="ticketNotAssignedCount > 0 ? 'endUserAlert' : 'endUserNone'">
                {{ $t("{ticketCount} Tickets Not Assigned", {ticketCount: ticketNotAssignedCount}) }}</p>
              <p :class="openTicketCount > 0 ? 'otherAlert' : 'endUserNone'">
                {{ $t("{ticketCount} Open Tickets", {ticketCount: openTicketCount}) }}</p>
            </div>
            <div v-else>
              <Skeleton class="p-my-3" height="1.6rem" width="70%" borderRadius="16px"/>
              <Skeleton class="p-my-3" height="1.6rem" width="50%" borderRadius="16px"/>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>

import ApiService from '../service/ApiService';

export default {
  name: "TicketsCard",
  props: {
    country: {
      type: String
    }
  },
  components: {},
  data() {
    return {
      tickets: [],
      loading: true,
      ticketNotAssignedCount: 0,
      openTicketCount: 0,
    }
  },

  created() {
    this.api = new ApiService();
    this.reloadTickets();
  },
  mounted() {
  },
  methods: {
      roleToCountry(){
          const countries = [];
          if (this.$auth.user.allRoles.includes('dashboard-country-rw')) countries.push('rwanda');
          if (this.$auth.user.allRoles.includes('dashboard-country-na')) countries.push('namibia');
          if (this.$auth.user.allRoles.includes('dashboard-country-drc')) countries.push('drc');
          if (this.$auth.user.allRoles.includes('dashboard-country-za')) countries.push('zambia');
          if (this.$auth.user.allRoles.includes('dashboard-country-ug')) countries.push('uganda');
          if (this.$auth.user.allRoles.includes('dashboard-country-ca')) countries.push('canada');
          if (this.$auth.user.allRoles.includes('dashboard-country-usa')) countries.push('usa');
          if (this.$auth.user.allRoles.includes('dashboard-country-br')) countries.push('brazil');
          if (this.$auth.user.allRoles.includes('dashboard-country-in')) countries.push('india');
          return countries;
      },
    reloadTickets() {
      this.api.getFromEp('tickets', {
        filters: JSON.stringify([{
          field: 'type',
          op: "not-in",
          value: ['platform_access_request', 'template']
        }])
      }).then((tickets) => {
        this.tickets = tickets;
        this.loading = false;


        this.tickets = tickets.filter(t => this.roleToCountry().includes(t.ticket.form['00010country'].value) && t.ticket.active === true);
        this.openTicketCount = this.tickets.length;
        this.ticketNotAssignedCount = this.tickets.filter(t => t.ticket.assignee === undefined || t.ticket.assignee === null).length;
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.o-mt-0 {
  margin-top: 0px !important;
}

.o-mb-0 {
  margin-bottom: 0px !important;
}

.endUserAlert {
  color: #A13D2D;
  font-size: 20px;
  font-weight: bold;
}

.otherAlert {
  color: #CCCC00;
  font-size: 20px;
  font-weight: bold;
}

.endUserNone {
  color: #A9A9A9;
  font-size: 20px;
  font-weight: bold;
}

.alertTitle {
  //   overflow: hidden;
  white-space: nowrap;
  color: #A9A9A9;
  font-size: 20px;
  font-weight: bold;
}
</style>
