<template>
    <div>
        <Card class="p-m-2 o-stat-card">
            <template #title>
                <div>{{title}}</div>
            </template>
            <template #content>
                <div class="o-jumbo">{{value}}</div>
            </template>
        </Card>
    </div>
</template>

<script>

export default {
	props: {
		value: Number,
        title: String
	},
	data() {
		return {
		};
	},
	methods: {
	},
	components: {
	},
};
</script>

<style scoped>
.o-jumbo {
    font-size: 500%;
}

.o-stat-card  {
    min-height: 175px;
}
</style>
