<template>
    <div>
        <Toast />
        <div class="p-grid">
            <div class="p-col-6">
                <div class="p-grid">
                    <StatCard class="p-col-4" v-model="suspect_scans_count" :title="$t('Suspect Scans')" />
                    <StatCard class="p-col-4" v-model="invalid_scans_count" :title="$t('Invalid Scans')" />
                    <StatCard class="p-col-4" v-model="unsupported_scans_count" :title="$t('Unsupported Scans')" />
                    <div class="p-col-12 p-p-2 o-o-chart">
                        <Chart  type="bar" :data="issues_by_country" :options="barChartOptions" />
                    </div>
                </div>
            </div>
            <ClusterMap class="p-col-6" v-model="scan_by_location"/>
        </div>
        <div>
            <TicketsCard />
        </div>
    </div>
</template>

<script>

import TicketsCard from '../components/TicketCards.vue';
import ClusterMap from '../components/ClusterMap.vue';
import StatCard from '../components/StatCard.vue';
import ApiService from '../service/ApiService';

export default {
    name: "Overview",
    props: {
        data: Object,
    },
    components: {
      TicketsCard,
      ClusterMap,
      StatCard
    },
    data: () => ({
        scan_by_location: {},
        issues_by_country: {},
        suspect_scans_count: null,
        invalid_scans_count: null,
        unsupported_scans_count: null,
        barChartOptions: {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        },

    }),
    created() {
        this.api = new ApiService();
        this.api.getFromEp('reports', {name: 'scan_by_location', countries: this.roleToCountry()}).then(data => {
            this.scan_by_location = data;
        })
        this.api.getFromEp('reports', {name: 'issues_by_country', countries: this.roleToCountry()}).then(data => {
            this.issues_by_country = data;
        })
        this.api.getFromEp('reports', {name: 'suspect_scans_count', countries: this.roleToCountry()}).then(data => {
            this.suspect_scans_count = data.count;
        })
        this.api.getFromEp('reports', {name: 'invalid_scans_count', countries: this.roleToCountry()}).then(data => {
            this.invalid_scans_count = data.count;
        })
        this.api.getFromEp('reports', {name: 'unsupported_scans_count', countries: this.roleToCountry()}).then(data => {
            this.unsupported_scans_count = data.count;
        })
    },
    destroyed() {
    },
    methods: {
        roleToCountry(){
            const countries = [];
            if (this.$auth.user.allRoles.includes('dashboard-country-rw')) countries.push('rwanda');
            if (this.$auth.user.allRoles.includes('dashboard-country-na')) countries.push('namibia');
            if (this.$auth.user.allRoles.includes('dashboard-country-drc')) countries.push('drc');
            if (this.$auth.user.allRoles.includes('dashboard-country-za')) countries.push('zambia');
            if (this.$auth.user.allRoles.includes('dashboard-country-ug')) countries.push('uganda');
            if (this.$auth.user.allRoles.includes('dashboard-country-ca')) countries.push('canada');
            if (this.$auth.user.allRoles.includes('dashboard-country-usa')) countries.push('usa');
            if (this.$auth.user.allRoles.includes('dashboard-country-br')) countries.push('brazil');
            if (this.$auth.user.allRoles.includes('dashboard-country-in')) countries.push('india');
            return countries;
        },
    }
};
</script>


<style lang="scss" scoped>
.o-o-chart {
    height: 500px;
    box-sizing: border-box;
    width: 100%;
}
</style>
